::v-deep .el-dialog__header {
  background-color: #FFFFFF;
}
::v-deep .el-dialog__header .el-dialog__title {
  margin-top: 10px;
  color: #333333;
}
::v-deep .el-dialog__header .el-dialog__close {
  color: #333333;
}
::v-deep .el-dialog__body {
  padding: 10px 24px 24px 24px;
}
.container-css {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
}
.container-css .order_title_css {
  padding: 10px;
  background: #F7F8FA;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.container-css .order_title_css .left_css .title_css {
  padding-right: 8px;
  font-weight: 600;
  color: #333333;
}
.container-css .order_title_css .left_css .context_css {
  padding-left: 8px;
  color: #666666;
}
.container-css .order_title_css .right_css {
  width: 70px;
  font-weight: 500;
  color: #333333;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.container-css .order_product_css {
  margin-top: 16px;
  padding: 0 12px 12px 12px;
  background: #F7F8FA;
}
.container-css .order_product_css .product_css {
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
}
.container-css .order_product_css .product_css .left_css {
  color: #333333;
  line-height: 14px;
}
.container-css .order_product_css .product_css .right_css {
  color: #333333;
  line-height: 14px;
}
.container-css .order_amount_css {
  margin-top: 16px;
  padding: 16px 12px 0 12px;
  border-top: 2px dashed #DDDDDD;
}
.container-css .order_amount_css .amount_css {
  display: flex;
  justify-content: space-between;
}
.container-css .order_amount_css .amount_css .left_css {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.container-css .order_amount_css .amount_css .right_css1 {
  padding-bottom: 12px;
  font-weight: 500;
}
.container-css .order_amount_css .amount_css .right_css1 .inner_css1 {
  color: #666666;
}
.container-css .order_amount_css .amount_css .right_css1 .inner_css2 {
  font-weight: 500;
  color: #EF4C4C;
}
.container-css .order_amount_css .amount_css .right_css2 {
  font-weight: 400;
}
.container-css .order_refund_css {
  margin-top: 15px;
  padding: 12px 12px 6px 12px;
  background: rgba(255, 146, 46, 0.1);
}
.container-css .order_refund_css .row_css {
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
}
.container-css .order_refund_css .row_css .right_refund_css {
  font-weight: 550;
  color: #EF4C4C;
}
.container-css .order_refund_css .row_last_css {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
}
.container-css .order_refund_css .row_last_css .refund_reason_css {
  color: #EF4C4C;
}
.container-css .order_line_css {
  padding: 8px 12px;
  border-bottom: 2px dashed #DDDDDD;
}
.container-css .order_payment_css {
  padding: 16px 12px 0 12px;
}
.container-css .order_payment_css .payment_css {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
}
.container-css .order_payment_css .payment_css .left_css {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 14px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.container-css .order_payment_css .payment_css .right_css {
  color: #666666;
  font-weight: 400;
}
.container-css .order_client_title_css {
  margin: 4px 0;
  padding: 12px;
  background: #F7F8FA;
  display: inline-flex;
  justify-content: space-between;
  color: #333333;
  font-weight: 600;
}
.container-css .order_client_title_css .info_css {
  font-weight: 500;
}
.container-css .order_client_content_css .client_content_css {
  padding: 12px 12px 0 12px;
  display: flex;
  justify-content: space-between;
}
.container-css .order_client_content_css .client_content_css .left_css {
  color: #666666;
}
.container-css .order_client_content_css .client_content_css .right_css {
  color: #666666;
}
.container-css .order_client_content_css .client_content_last_css {
  padding: 6px 12px;
}
