





















































































































































































































































































































::v-deep .el-dialog__header {
  background-color: #FFFFFF;
}
::v-deep .el-dialog__header .el-dialog__title {
  margin-top: 10px;
  color: #333333;
}
::v-deep .el-dialog__header .el-dialog__close {
  color: #333333;
}
::v-deep .el-dialog__body {
  padding: 10px 24px 24px 24px;
}
.container-css {
  width: 100%;
  display:inline-flex;
  flex-direction: column;
  justify-content: flex-start;

  .order_title_css {
    padding: 10px;
    background: #F7F8FA;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    .left_css {
      .title_css {
        padding-right: 8px;
        font-weight: 600;
        color: #333333;
      }
      .context_css {
        padding-left: 8px;
        color: #666666;
      }
    }
    .right_css {
      width: 70px;
      font-weight: 500;
      color: #333333;
      line-height: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
  }
  .order_product_css {
    margin-top: 16px;
    padding: 0 12px 12px 12px;
    background: #F7F8FA;
    .product_css {
      padding-top: 12px;
      display: flex;
      justify-content: space-between;
      .left_css {
        color: #333333;
        line-height: 14px;
      }
      .right_css {
        color: #333333;
        line-height: 14px;
      }
    }
  }
  .order_amount_css {
    margin-top: 16px;
    padding: 16px 12px 0 12px;
    border-top: 2px dashed #DDDDDD;
    .amount_css {
      display: flex;
      justify-content: space-between;
      .left_css {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 14px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .right_css1 {
        padding-bottom: 12px;
        font-weight: 500;
        .inner_css1 {
          color: #666666;
        }
        .inner_css2 {
          font-weight: 500;
          color: #EF4C4C;
        }
      }
      .right_css2 {
        font-weight: 400;
      }
    }
  }
  .order_refund_css {
    margin-top: 15px;
    padding: 12px 12px 6px 12px;
    background: rgba(255,146,46,0.1);
    .row_css {
      padding-bottom: 8px;
      display: flex;
      justify-content: space-between;
      .right_refund_css {
        font-weight: 550;
        color: #EF4C4C;
      }
    }
    .row_last_css {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 8px;
      .refund_reason_css {
        color: #EF4C4C;
      }
    }

  }
  .order_line_css {
    padding: 8px 12px;
    border-bottom: 2px dashed #DDDDDD;
  }
  .order_payment_css {
    padding: 16px 12px 0 12px;
    .payment_css {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;
      .left_css {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 14px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .right_css {
        color: #666666;
        font-weight: 400;
      }
    }
  }
  .order_client_title_css {
    margin: 4px 0;
    padding: 12px;
    background: #F7F8FA;
    display: inline-flex;
    justify-content: space-between;
    color: #333333;
    font-weight: 600;
    .info_css {
      font-weight: 500;
    }
  }
  .order_client_content_css {
    .client_content_css {
      padding: 12px 12px 0 12px;
      display: flex;
      justify-content: space-between;
      .left_css {
        color: #666666;
      }
      .right_css {
        color: #666666;
      }
    }
    .client_content_last_css {
      padding: 6px 12px;
    }
  }
}
